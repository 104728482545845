import { frontendLoggerService } from "@telia/b2x-rest-client";

// the separator is to be able to split the string in Splunk queries,
// so that we can group errors by packageName, message etc
const SEPARATOR = " --- ";
const ESCAPED_SEPARATOR = " - - ";

/**
 * Log a frontend error to backend (Splunk)
 * @async but in most cases the caller SHOULD NOT wait for it
 * @param {string} packageName - The name of the micro-app/library/service etc in which the error happened e.g. "b2b-subscription-search"
 * @param {string} message - A static description of the error, e.g. "Unexpected number of splines to reticulate". Avoid variables, so that number of events can be counted and grouped in Splunk based on this string. Avoid using the same string from multiple calls.
 * @returns {Promise<void>} Always resolves to void when call to backend has been completed, even if call fails.
 */
export async function logError(packageName: string, message: string): Promise<void> {
  return log(packageName, message, "ERROR");
}

export async function logInfo(packageName: string, message: string): Promise<void> {
  return log(packageName, message, "INFO");
}

/**
 * Log a frontend error to backend (Splunk)
 * @async but in most cases the caller SHOULD NOT wait for it
 * @param {string} packageName - The name of the micro-app/library/service etc in which the error happened e.g. "b2b-subscription-search"
 * @param {string} message - A static description of the error, e.g. "Unexpected number of splines to reticulate". Avoid variables, so that number of events can be counted and grouped in Splunk based on this string. Avoid using the same string from multiple calls.
 * @param {"ERROR" | "INFO"} - Loglevel
 * @returns {Promise<void>} Always resolves to void when call to backend has been completed, even if call fails.
 */
async function log(
  packageName: string,
  message: string,
  logLevel: "ERROR" | "INFO"
): Promise<void> {
  try {
    await frontendLoggerService.FrontendLoggerControllerService.logUsingPost({
      logLevel: logLevel,
      message: escapeSeparator(packageName) + SEPARATOR + escapeSeparator(message),
      title: "NTSE Frontend", // distinguish from "Mitt Telia", "Common frontend analytics", etc
    });
  } catch (e) {
    // failed to log error :(
  }
}

/**
 * Ensure string being logged to Splunk does not contain the separator
 * @param unescaped A string which may contain SEPARATOR
 * @returns A string in which all SEPARATOR have been replaced by ESCAPED_SEPARATOR
 */
function escapeSeparator(unescaped: string): string {
  // better but requires es2021: return unescaped.replaceAll(SEPARATOR, ESCAPED_SEPARATOR);

  return unescaped.replace(new RegExp(SEPARATOR, "g"), ESCAPED_SEPARATOR);
}

// Ideas for improvements: SEE README
